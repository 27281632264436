import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import { Layout } from '@components/layout';
import { Mypage } from '@features/mypage';
import { EditAddress } from '@features/mypage/BasicInfo/EditAddress';

const Edit: FC<PageProps> = ({ params }) => {
  const id = parseInt(params.id);

  return (
    <Layout>
      <Mypage>
        <EditAddress editId={id} />
      </Mypage>
    </Layout>
  );
};
export default Edit;
