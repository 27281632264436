import { navigate } from 'gatsby';
import React from 'react';

import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { genderMapping } from '@constants/mapping';
import { MypageBasicInfoDescription } from '@features/mypage/BasicInfo/components/MypageBasicInfoDescription';
import { User } from '@pb/api/v1/user/common_pb';

type Props = {
  user: User.AsObject;
};

export const UserPreview = ({ user }: Props) => {
  if (user === undefined) {
    return <p>Loading...</p>;
  }

  return (
    <Block paddingTop="22px" borderBottom="1px solid var(--color-border-gray2)">
      <MypageBasicInfoDescription
        data={user}
        header="会員情報"
        paragraphProps={{
          color: 'black',
        }}
        blockProps={{
          marginBottom: '31px',
        }}
        gridProps={{
          gridTemplateColumns: '77px 1fr',
          alignItems: 'center',
          rowGap: '29px',
        }}
        titles={[
          {
            title: '名前',
            accessorFn: ({ firstName, lastName }) => `${lastName} ${firstName}`,
          },
          {
            title: 'フリガナ',
            accessorFn: ({ firstKana, lastKana }) => `${lastKana} ${firstKana}`,
          },
          {
            title: '性別',
            accessorFn: ({ gender }) => genderMapping[gender],
          },
          {
            title: '生年月日',
            accessorFn: ({ birthday }) => new Date(birthday).toLocaleDateString(),
          },
          {
            title: '郵便番号',
            accessorKey: 'zipCode',
          },
          {
            title: '住所',
            accessorFn: ({ prefecture, municipality, address1, address2 }) =>
              `${prefecture}${municipality}${address1}${address2}`,
          },
          {
            title: '電話番号',
            accessorKey: 'phoneNumber',
          },
        ]}
      />
      <Block paddingBottom="30px" margin="0 auto" width="fit-content">
        <Button
          type="submit"
          width="152px"
          height="35px"
          radius="5px"
          bg="gray2"
          border="1px solid var(--color-border-gray2)"
          color="black"
          onClick={() => navigate('/mypage/basic-info/user/edit')}
        >
          変更する
        </Button>
      </Block>
    </Block>
  );
};
